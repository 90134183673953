/*
  Syntax Highlighting for Code Snippets

  https://www.bridgetownrb.com/docs/liquid/tags#stylesheets-for-syntax-highlighting

  Other styles available eg. https://github.com/jwarby/jekyll-pygments-themes
  To use another style, delete all styles in this file and replace them with
  the new styles. Or create your own!

*/

div.highlighter-rouge {
    position: relative;

    &::before {
        font-size: 70%;
        font-weight: 600;
        /* border-radius: 1em; */
        background: #a855f7;
        color: white;
        padding: 0.2em 0.8em;
        position: absolute;
        right: -0.6em;
        top: -0.6em;
        box-shadow: 0px 7px 9px rgba(0, 0, 0, 0.1);
    }

    &.language-sh::before,
    &.language-shell::before {
        content: "Shell";
    }
    &.language-rb::before,
    &.language-ruby::before {
        content: "Ruby";
    }
    &.language-js::before {
        content: "JavaScript";
    }
    &.language-json::before {
        content: "JSON";
    }
    &.language-html::before {
        content: "HTML";
    }
    &.language-css::before {
        content: "CSS";
    }
    &.language-liquid::before {
        content: "Liquid";
    }
    &.language-eruby::before,
    &.language-erb::before {
        content: "ERB";
    }
    &.language-serb::before {
        content: "Serbea";
    }
    &.language-md::before {
        content: "Markdown";
    }
    &.language-yml::before,
    &.language-yaml::before {
        content: "YAML";
    }
    &.language-Dockerfile::before {
        content: "Dockerfile";
    }
    &.language-nginx::before {
        content: "NGINX";
    }
}

code.highlighter-rouge {
    padding: 0.2em 0.4em;
}

code.highlighter-rouge,
.highlight,
.highlight .w {
    border-radius: 4px;
}

pre.highlight {
    padding: 16px;
    overflow-x: scroll;
}
.highlight table td {
    padding: 5px;
}
.highlight table pre {
    margin: 0;
}

/* Light theme */
code.highlighter-rouge,
.highlight,
.highlight .w {
    color: #24292f;
    background-color: #e4e4e7;
}
.highlight .k,
.highlight .kd,
.highlight .kn,
.highlight .kp,
.highlight .kr,
.highlight .kt,
.highlight .kv {
    color: #cf222e;
}
.highlight .gr {
    color: #f6f8fa;
}
.highlight .gd {
    color: #82071e;
    background-color: #ffebe9;
}
.highlight .nb {
    color: #953800;
}
.highlight .nc {
    color: #953800;
}
.highlight .no {
    color: #953800;
}
.highlight .nn {
    color: #953800;
}
.highlight .sr {
    color: #116329;
}
.highlight .na {
    color: #116329;
}
.highlight .nt {
    color: #116329;
}
.highlight .gi {
    color: #116329;
    background-color: #dafbe1;
}
.highlight .ges {
    font-weight: bold;
    font-style: italic;
}
.highlight .kc {
    color: #0550ae;
}
.highlight .l,
.highlight .ld,
.highlight .m,
.highlight .mb,
.highlight .mf,
.highlight .mh,
.highlight .mi,
.highlight .il,
.highlight .mo,
.highlight .mx {
    color: #0550ae;
}
.highlight .sb {
    color: #0550ae;
}
.highlight .bp {
    color: #0550ae;
}
.highlight .ne {
    color: #0550ae;
}
.highlight .nl {
    color: #0550ae;
}
.highlight .py {
    color: #0550ae;
}
.highlight .nv,
.highlight .vc,
.highlight .vg,
.highlight .vi,
.highlight .vm {
    color: #0550ae;
}
.highlight .o,
.highlight .ow {
    color: #0550ae;
}
.highlight .gh {
    color: #0550ae;
    font-weight: bold;
}
.highlight .gu {
    color: #0550ae;
    font-weight: bold;
}
.highlight .s,
.highlight .sa,
.highlight .sc,
.highlight .dl,
.highlight .sd,
.highlight .s2,
.highlight .se,
.highlight .sh,
.highlight .sx,
.highlight .s1,
.highlight .ss {
    color: #0a3069;
}
.highlight .nd {
    color: #8250df;
}
.highlight .nf,
.highlight .fm {
    color: #8250df;
}
.highlight .err {
    color: #f6f8fa;
    background-color: #82071e;
}
.highlight .c,
.highlight .ch,
.highlight .cd,
.highlight .cm,
.highlight .cp,
.highlight .cpf,
.highlight .c1,
.highlight .cs {
    color: #6e7781;
}
.highlight .gl {
    color: #6e7781;
}
.highlight .gt {
    color: #6e7781;
}
.highlight .ni {
    color: #24292f;
}
.highlight .si {
    color: #24292f;
}
.highlight .ge {
    color: #24292f;
    font-style: italic;
}
.highlight .gs {
    color: #24292f;
    font-weight: bold;
}

/* Dark theme */
.dark code.highlighter-rouge,
.dark .highlight,
.dark .highlight .w {
    color: #c9d1d9;
    background-color: #27272a;
}
.dark .highlight .k,
.dark .highlight .kd,
.dark .highlight .kn,
.dark .highlight .kp,
.dark .highlight .kr,
.dark .highlight .kt,
.dark .highlight .kv {
    color: #ff7b72;
}
.dark .highlight .gr {
    color: #f0f6fc;
}
.dark .highlight .gd {
    color: #ffdcd7;
    background-color: #67060c;
}
.dark .highlight .nb {
    color: #ffa657;
}
.dark .highlight .nc {
    color: #ffa657;
}
.dark .highlight .no {
    color: #ffa657;
}
.dark .highlight .nn {
    color: #ffa657;
}
.dark .highlight .sr {
    color: #7ee787;
}
.dark .highlight .na {
    color: #7ee787;
}
.dark .highlight .nt {
    color: #7ee787;
}
.dark .highlight .gi {
    color: #aff5b4;
    background-color: #033a16;
}
.dark .highlight .ges {
    font-weight: bold;
    font-style: italic;
}
.dark .highlight .kc {
    color: #79c0ff;
}
.dark .highlight .l,
.dark .highlight .ld,
.dark .highlight .m,
.dark .highlight .mb,
.dark .highlight .mf,
.dark .highlight .mh,
.dark .highlight .mi,
.dark .highlight .il,
.dark .highlight .mo,
.dark .highlight .mx {
    color: #79c0ff;
}
.dark .highlight .sb {
    color: #79c0ff;
}
.dark .highlight .bp {
    color: #79c0ff;
}
.dark .highlight .ne {
    color: #79c0ff;
}
.dark .highlight .nl {
    color: #79c0ff;
}
.dark .highlight .py {
    color: #79c0ff;
}
.dark .highlight .nv,
.dark .highlight .vc,
.dark .highlight .vg,
.dark .highlight .vi,
.dark .highlight .vm {
    color: #79c0ff;
}
.dark .highlight .o,
.dark .highlight .ow {
    color: #79c0ff;
}
.dark .highlight .gh {
    color: #1f6feb;
    font-weight: bold;
}
.dark .highlight .gu {
    color: #1f6feb;
    font-weight: bold;
}
.dark .highlight .s,
.dark .highlight .sa,
.dark .highlight .sc,
.dark .highlight .dl,
.dark .highlight .sd,
.dark .highlight .s2,
.dark .highlight .se,
.dark .highlight .sh,
.dark .highlight .sx,
.dark .highlight .s1,
.dark .highlight .ss {
    color: #a5d6ff;
}
.dark .highlight .nd {
    color: #d2a8ff;
}
.dark .highlight .nf,
.dark .highlight .fm {
    color: #d2a8ff;
}
.dark .highlight .err {
    color: #f0f6fc;
    background-color: #8e1519;
}
.dark .highlight .c,
.dark .highlight .ch,
.dark .highlight .cd,
.dark .highlight .cm,
.dark .highlight .cp,
.dark .highlight .cpf,
.dark .highlight .c1,
.dark .highlight .cs {
    color: #8b949e;
}
.dark .highlight .gl {
    color: #8b949e;
}
.dark .highlight .gt {
    color: #8b949e;
}
.dark .highlight .ni {
    color: #c9d1d9;
}
.dark .highlight .si {
    color: #c9d1d9;
}
.dark .highlight .ge {
    color: #c9d1d9;
    font-style: italic;
}
.dark .highlight .gs {
    color: #c9d1d9;
    font-weight: bold;
}
