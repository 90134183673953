/* If you need to add @import statements, do so up here */

@import "jit-refresh.css"; /* triggers frontend rebuilds */

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    a {
        @apply underline text-purple-800 hover:text-purple-900 visited:text-purple-700 active:text-purple-800 dark:text-purple-300 dark:hover:text-purple-200 dark:active:text-purple-300 dark:visited:text-purple-400;
    }
}
